import { useEffect, useState } from "react";
import { FiArrowDown } from "react-icons/fi";

export default function InstallPWAButton() {
  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);
  const [showButton, setShowButton] = useState<boolean>(false);

  useEffect(() => {
    const handler = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e as BeforeInstallPromptEvent);
      setShowButton(true);
    };

    window.addEventListener("beforeinstallprompt", handler);
    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const handleInstall = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === "accepted") {
        console.log("User accepted the install");
      } else {
        console.log("User dismissed the install");
      }
      setDeferredPrompt(null);
      setShowButton(false);
    }
  };

  return (
    showButton && (
      <div className="w-full h-8 bg-blue-500 px-8 flex items-center gap-4">
        <p className="font-bold text-sm">
          Experience seamless trading with Level2 - Install the app now{" "}
        </p>
        <button
          onClick={handleInstall}
          className="bg-white rounded-sm text-blue-500 text-sm px-2 font-bold flex justify-between gap-2 items-center"
        >
          Install
          <FiArrowDown />
        </button>
      </div>
    )
  );
}

// TypeScript interface for BeforeInstallPromptEvent
interface BeforeInstallPromptEvent extends Event {
  prompt: () => void;
  userChoice: Promise<{ outcome: "accepted" | "dismissed" }>;
}
